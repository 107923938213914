<script setup lang="ts"></script>

<template>
  <layout-header-component />
  <router-view />
  <layout-footer-component />
  <manage-cookies-component />
  <layout-page-loader-component />
</template>

<style>
body {
  margin-top: 60px;
}
@media (min-width: theme('screens.big')) {
  body {
    margin-top: 82px;
  }
}
</style>
